import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { UserStateService } from 'app/state/user/user-state.service';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { GetPasswordComponent } from './get-password/get-password.component';
	
// All modules that have been converted for module access use.
// This should be the central list of active business areas in HOMER, and should be maintained. Terry R. 11/14/24
export const availableModules: BusinessAreaEnum[] = [
	BusinessAreaEnum.Admin
    , BusinessAreaEnum.CI
    , BusinessAreaEnum.ErrorReport
    , BusinessAreaEnum.HEROS
    , BusinessAreaEnum.ICQ
    , BusinessAreaEnum.Inventory
    , BusinessAreaEnum.MetabolicFormula
    , BusinessAreaEnum.NDC
    , BusinessAreaEnum.RxConnects
    , BusinessAreaEnum.HelpDesk
    , BusinessAreaEnum.SysInv
    , BusinessAreaEnum.BA
];

@Injectable({ providedIn: 'root' })
export class SecurityService {

	user$: Observable<UserJsVm>;

	private user: UserJsVm;
	private subscriptions = new Subscription();
	private userSource: BehaviorSubject<UserJsVm>;

	static setMinRole = (role: PermissionRole | number): PermissionRole[] => Object.values(PermissionRole)
		// Ensure there are only numbers are flattening values from PermissionRole
		.filter(v => typeof v === 'number')
		// Filters out values that are greater than or equal to the role passed in
		.filter(v => v as number >= role)
		// Creates array of permission roles based on min. value 
		.map(v => v as PermissionRole);
	static getMinBaseRoles = (role: PermissionRole | number): PermissionRole[] => SecurityService.setMinRole(role)
		// filter out getBaseRoles PermissionRoles
		.filter(v => !PermissionRole.toDescription(v).includes('RxConnects'));
	static anyArea = (): BusinessAreaEnum[] => Object.values(BusinessAreaEnum).map(v => v as BusinessAreaEnum);

	constructor(
		private jwtHelper: JwtHelperService
		, private router: Router
		, private modal: NgbModal
		, private userState: UserStateService
	) {
		this.user = this.getUser();
		this.userSource = new BehaviorSubject<UserJsVm>(this.user);
		this.user$ = this.userSource.asObservable();

		// Subscribe to route changes and update the security when they happen
		this.subscriptions.add(
			this.router.events.pipe(
				filter(event => event instanceof NavigationStart)
			).subscribe(() =>
				this.setSecurity(
					this.getToken()
					, this.getUser()
				)
			)
		);
	}

	/**
	 * Set the globally used auth token, user, and dynamic security.
	 * Setting any parameter as undefined will leave it as it is.
	 * Setting any parameter to null will remove the value.
	 * @param jwt The token to user for authorization
	 * @param user User information to be used in components
	 */
	setSecurity(
		jwt: string
		, user: UserJsVm
	) {
		// Set token
		if (jwt !== undefined) {
			this.setLocalStorage('jwt', jwt);
			if (jwt === null) { document.cookie = 'JsonWebToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'; }
		}

		// Set user
		if (user !== undefined) {
			this.user = user;
			this.userSource.next(user);
			this.userState.updateUser(user);
		}
	}

	setLocalStorage(
        name: string
        , value: any
    ) {
		if (value === null) { localStorage.removeItem(name); }
		else { localStorage.setItem(name, JSON.stringify(value)); }
	}

	/** Get the JSON web token */
	getToken() { return localStorage.getItem('jwt') !== null ? JSON.parse(localStorage.getItem('jwt')) : null;}

	/** Get the user JS object */
	getUser = (): UserJsVm => this.userState.user();

	/** Check whether or not the user is logged on */
	isLoggedOn() {
		const token = this.getToken();
		if (
			(
				token 
				&& this.jwtHelper.isTokenExpired(token)
			) 
			|| (
				!token 
				&& this.user
			)
		) { this.setSecurity(null, null); }
		return token && !this.jwtHelper.isTokenExpired(token);
	}

	/**
	 * Check whether or not the user has access to an area
	 * @param securityArea Either a function that returns a bool or a string
	 *  that says which security setting is needed. If it's a string, it should
	 *  be in the form "Module.SecuritySetting"
	 */
	hasAccess(
		requireLogin = true
		, modules: BusinessAreaEnum[] = []
		, permissionRoles: PermissionRole[] = []
	) {
		// If they are not logged on, they do not have access
		if (
            requireLogin 
            && !this.isLoggedOn()
        ) { return false; }
		// If no security information passed in, we are only making sure user
		// is logged in, so return true
		// If module and permission role are supplied check ModuleAccess
		else if (
            modules?.length > 0 
            && permissionRoles?.length > 0
        ) { return this.hasModuleAccess(modules, permissionRoles); }
		return false;
	}

	/**
	 * Checks user Module Access against module access required by the nav.ts file
	 * @param modules BusinessAreaEnums passed from the nav.ts file
	 * @param permissionRoles PermissionRoles passed from the nav.ts file
	 * @returns True if user has module access, False if not
	 */
	hasModuleAccess = (
		modules: BusinessAreaEnum[]
		, permissionRoles: PermissionRole[] = [PermissionRole.User]
		, sysAdminOverride: boolean = true
	) => this.userState?.user()?.moduleAccess?.some(o => 
			modules.includes(o.module) 
			&& (
				permissionRoles.includes(o.permissionRole as PermissionRole)
				|| (
					o.permissionRole === PermissionRole.SystemAdmin 
					&& sysAdminOverride
				)
			)
	);

	/**
	 * Check for access to security area. Redirect to root if they do not have access.
	 * @param securityArea Either a function that returns a bool or a string
	 *  that says which security setting is needed. If it's a string, it should
	 *  be in the form "Module.SecuritySetting"
	 */
	checkSecurity(
		modules: BusinessAreaEnum[] = []
		, permissionRoles: PermissionRole[] = []
	) {
		// If they have access to the area, return true
		if (this.hasModuleAccess(modules, permissionRoles)) { return true; }

		// They do not have access, so redirect to root and return false
		this.router.navigate(['/']);
		return false;
	}

	/**
	 * Get a list of nav items with valid security.
	 * @param routes A list of nav items to check the security of.
	 */
	getSecureNavItems(routes: NavRoute[]) {
		let newNavItems = [];
		for (let i = 0; i < routes.length; ++i) {
			let route = routes[i];
			if (route) { 
				let canAccess: boolean = !route?.moduleAccess?.length;
				if (route.moduleAccess?.length) {
					canAccess = (route?.requireAll)
						// All provided module access permissions must be met when requireAll is true. 
						? !route?.moduleAccess?.some(o => !this.hasModuleAccess(o?.modules, o?.permissionRoles))
						// Only one provided module access permission must be met when requireAll is not true.
						: route?.moduleAccess?.some(o => this.hasModuleAccess(o?.modules, o?.permissionRoles));
				} 

				if (canAccess) {
					if (route.children?.length > 0) {
						route.children = this.getSecureNavItems(route.children);
					}

					const featureFlag = route?.featureFlag;				
					if (!featureFlag || this.user?.email?.includes("@paulconsulting.com")) {
						newNavItems.push(route);
					} 
				}
			}			
		};
		return newNavItems;
	}

	setControlModuleAccess(
		control: AbstractControl
		, modules: BusinessAreaEnum[]
		, permissionRoles: PermissionRole[]
	) {
		if (this.hasModuleAccess(modules, permissionRoles)) { control.enable(); }
		else { control.disable(); }
	}

	setFormModuleAccess(
		form: UntypedFormGroup
		, modules: BusinessAreaEnum[]
		, permissionRoles: PermissionRole[]
	) {
		if (this.hasModuleAccess(modules, permissionRoles)) { form.enable(); }
		else { form.disable(); }
	}

	/**
	 * Prompt for a secure password to use when encrypting exports.
	 * @param func The function to run after a password has been chosen.
	 * @param message The message to display when prompting for a password.
	 * @param title The title of the password prompt.
	 */
	promptPassword(func: ((str: string) => any),
		message = 'In order to download this file, it must be password protected.',
		title = 'Choose a password',
		promptForUsersPassword = false
	) {
		const modRef = this.modal.open(GetPasswordComponent);
		(modRef.componentInstance as GetPasswordComponent).message = message;
		(modRef.componentInstance as GetPasswordComponent).title = title;
		(modRef.componentInstance as GetPasswordComponent).func = func;
		(modRef.componentInstance as GetPasswordComponent).promptForUsersPassword = promptForUsersPassword;
	}
}
