<div 
    [id]='"shadowContainer-" + instanceId'
    class="container rounded mw-100 p-0" 
    [ngClass]='{"shadow-bottom border-bottom": hasOverflow()}'
    (scroll)="onScroll($event)"
>    
    <div class='shadow-bottom rounded'>
        <!-- Content passed through from parent component -->
        <ng-content></ng-content>
        
        <!-- Anchor element used to check for overflow below the provided content -->
        <a [id]='"contentEnd-" + instanceId'></a>
    </div>
</div>
