// Applies styles for users in high contrast mode. Note that this only applies
@use '@angular/cdk' as cdk;
// to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
// attribute, however Chrome handles high contrast differently.
//
// @param target Which kind of high contrast setting to target. Defaults to `active`, can be
//    `white-on-black` or `black-on-white`.
@mixin cdk-high-contrast($target: active) {
    @media (-ms-high-contrast: $target) { @content; }
}

$pcg-sort-header-arrow-margin: 6px;
$pcg-sort-header-arrow-container-size: 12px;
$pcg-sort-header-arrow-stem-size: 10px;
$pcg-sort-header-arrow-pointer-length: 6px;
$pcg-sort-header-arrow-thickness: 2px;
$pcg-sort-header-arrow-hint-opacity: 0.38;

.pcg-sort-header-container {
    display: flex;
    cursor: pointer;
    align-items: center;

    .pcg-sort-header-disabled & { cursor: default; }
}

.pcg-sort-header-position-before { flex-direction: row-reverse; }

.pcg-sort-header-button {
    border: none;
    background: 0 0;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: inherit;
    outline: 0;
    font: inherit;
    color: currentColor;

    // The `outline: 0` from above works on all browsers, however Firefox also
    // adds a special `focus-inner` which we have to disable explicitly. See:
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Firefox
    &::-moz-focus-inner { border: 0; }
}

.pcg-sort-header-arrow {
    height: $pcg-sort-header-arrow-container-size;
    width: $pcg-sort-header-arrow-container-size;
    min-width: $pcg-sort-header-arrow-container-size;
    position: relative;
    display: flex;

    // Start off at 0 since the arrow may become visible while parent are animating.
    // This will be overwritten when the arrow animations kick in. See #11819.
    opacity: 0;

    &, [dir='rtl'] .pcg-sort-header-position-before & { margin: 0 0 0 $pcg-sort-header-arrow-margin; }
    .pcg-sort-header-position-before &, [dir='rtl'] & { margin: 0 $pcg-sort-header-arrow-margin 0 0; }
}

.pcg-sort-header-stem {
    background: currentColor;
    height: $pcg-sort-header-arrow-stem-size;
    width: $pcg-sort-header-arrow-thickness;
    margin: auto;
    display: flex;
    align-items: center;

    @include cdk.high-contrast {
        width: 0;
        border-left: solid $pcg-sort-header-arrow-thickness;
    }
}

.pcg-sort-header-indicator {
    width: 100%;
    height: $pcg-sort-header-arrow-thickness;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
}

.pcg-sort-header-pointer-middle {
    margin: auto;
    height: $pcg-sort-header-arrow-thickness;
    width: $pcg-sort-header-arrow-thickness;
    background: currentColor;
    transform: rotate(45deg);

    @include cdk.high-contrast {
        width: 0;
        height: 0;
        border-top: solid $pcg-sort-header-arrow-thickness;
        border-left: solid $pcg-sort-header-arrow-thickness;
    }
}

.pcg-sort-header-pointer-left,
.pcg-sort-header-pointer-right {
    background: currentColor;
    width: $pcg-sort-header-arrow-pointer-length;
    height: $pcg-sort-header-arrow-thickness;
    position: absolute;
    top: 0;

    @include cdk.high-contrast {
        width: 0;
        height: 0;
        border-left: solid $pcg-sort-header-arrow-pointer-length;
        border-top: solid $pcg-sort-header-arrow-thickness;
    }
}

.pcg-sort-header-pointer-left {
    transform-origin: right;
    left: 0;
}

.pcg-sort-header-pointer-right {
    transform-origin: left;
    right: 0;
}
