/* This file defines commonly changed PCG variables when
 * spinning up a new shell
 *
 * This file is imported in the main _variables.scss file
 */

// Primary colors
$primary-color: #78848F;
$accent-color: #666;
$workarea-color: #f2f2f2;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150;

// Customize the light and dark text colors for use in bootstrap's YIQ color contrast function.
$yiq-text-dark: #212529;
$yiq-text-light: #fff;

// Navbar
$sidenav-bg-color: #2c3e50;
$sidenav-item-hover-color: #222;

$main-navbar-bg-color: #2C3E50;
$main-navbar-text-color: color-yiq($main-navbar-bg-color);
$main-navbar-active-item-accent-color: rgb(181, 158, 93);
$main-navbar-active-item-text-color: $main-navbar-text-color;
$main-navbar-active-item-bg-color: rgb(64, 64, 64);

$main-navbar-active-item-bg-color: $primary-color;
$main-navbar-active-item-accent-color: #339cd6;
$main-navbar-active-item-text-color: $main-navbar-text-color;

$secondary-navbar-bg-color: #465666;
$secondary-navbar-text-color: rgb(255, 255, 255);
$secondary-navbar-active-text-color: #fff;
$secondary-navbar-active-bg-color: rgb(149, 194, 81);

// Tab sets
$nav-tabs-link-active-bg: $sidenav-bg-color;
$nav-tabs-link-active-color: color-yiq($nav-tabs-link-active-bg);
$nav-tabs-link-inactive-bg: $accent-color;
$nav-tabs-link-inactive-color: color-yiq($nav-tabs-link-inactive-bg);
$nav-tabs-link-hover-bg: darken($nav-tabs-link-active-bg, 10%);
$nav-tabs-link-hover-color: color-yiq($nav-tabs-link-hover-bg);
$nav-tabs-link-active-hover-bg: darken($sidenav-bg-color, 5%);

// Tables
$table-header-bg: $accent-color;
$table-header-text-color: color-yiq($table-header-bg);

// Table pagination
$pagination-bg: #fff;
$pagination-color: color-yiq($pagination-bg);
$pagination-hover-bg: #e9ecef;
$pagination-hover-color: color-yiq($pagination-hover-bg);
$pagination-border-color: #dee2e6;
$pagination-active-bg: $table-header-bg;
$pagination-active-color: $table-header-text-color;

// Buttons
$default-button-color: #6c757d;
$apply-filters-button-color: #078E30;
$cancel-button-color: #E0E0E0;
$save-button-color: #088725;
$view-button-color: #298cb2;
$delete-button-color: #dc3545;

// Collapsers and workflow dividers
$content-divider-color: $primary-color;
$content-divider-text-color: color-yiq($content-divider-color);

//Sets up some basic css for images
@mixin inputImage($imageURL) {
	background-image: $imageURL;
	background-repeat: no-repeat;
	background-size: contain;
	background-size: 1rem;
	background-repeat: no-repeat;
	background-position: 97% 50%;
	padding-right:2.1875rem;
}

// Misc Indicators
$homer-error-light-red-color:#f9d0d0;

