import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { of, throwError } from "rxjs";
import { flatMap, retryWhen, tap } from "rxjs/operators";
import { SystemMessage } from "./core/system-message/system-message-service";

const OVERRIDE_DEFAULT = { timeOut: 5000, positionClass: "toast-top-right" };

const MSG_TYPE = {
    success: "Success",
    error: "Error",
    info: "Information",
    warning: "Warning"
}

@Injectable({
	providedIn: "root",
})
export class NotificationService {

	constructor(private http: HttpClient, private toastr: ToastrService) {}

	showSuccess(message: string, override?: Partial<IndividualConfig>) {
		this.toastr.success(message, null, override ?? OVERRIDE_DEFAULT);
	}

	showError(message: string, override?: Partial<IndividualConfig>) {
		this.toastr.error(message, null, override ?? OVERRIDE_DEFAULT);
	}

	showInfo(message: string, override?: Partial<IndividualConfig>) {
		this.toastr.info(message, null, override ?? OVERRIDE_DEFAULT);
	}

	showWarning(message: string, override?: Partial<IndividualConfig>) {
		this.toastr.warning(message, null, override ?? OVERRIDE_DEFAULT);
	}

	showMessage(
		message: string,
		messageClass: string = MSG_TYPE.success,
		override?: Partial<IndividualConfig>
	) {
		override ??= OVERRIDE_DEFAULT;
        
        switch (messageClass.toLowerCase()) {
            case MSG_TYPE.success.toLowerCase():
                this.showSuccess(message, override);
                break;
            case MSG_TYPE.error.toLowerCase():
                this.showError(message, override);
                break;
            case MSG_TYPE.info.toLowerCase():
                this.showInfo(message, override);
                break;
            case MSG_TYPE.warning.toLowerCase():
                this.showWarning(message, override);
                break;
            default:
                this.showSuccess(message, override);
                break;
        }
	}
    
	/**
	 * Creates an observable which gets a system message from the server and processes it.
	 * Example Call: myForm$ = this.ms.getHttpObservable(this, 'api/Save', this.myForm);
	 * @param component A reference to the current component. Generally should just = this.
	 * @param url The location of the server API call that returns a system message.
	 * @param form The form group from which to get parameters to pass to API method.
	 * @param params Additional parameters pass to API method.
	 */
	getHttpObservable(url: string, form: UntypedFormGroup = null, params: Object = null) {
		const objToSend = { ...(form === null ? {} : form.getRawValue()), ...params };
		return this.http.post<SystemMessage>(url, objToSend, {})
			.pipe(
				tap(
					msg => {
						// Show the system message, if a message was given
						if (msg.message !== null && msg.message !== '' && msg.showSystemMessage !== false) {
							this.showMessage(msg.message, msg.messageClass);
						}
						// Merge model into form, if provided
						if (msg.model !== null && form !== null) { form.patchValue(msg.model); }
					}
				),
				// Retry when we receive a confirmation message and they click 'OK'
				retryWhen(errors => {
					return errors.pipe(flatMap(
						(err: HttpErrorResponse) => {
							// Error 466 is thrown when we do Notification.Confirm in the backend
							if (err.status === 466 && confirm(err.error)) {
								// Set confirmedMessages to an empty list, if it's undefined
								if (!objToSend.confirmedMessages) { objToSend.confirmedMessages = []; }
								// Append the message to the confirmed messages array on objToSend
								// so it will be sent when we retry the request
								objToSend.confirmedMessages.push(err.error);
								return of(err.status);
							}
							return throwError(err);
						}));
				})
			);
	}

}
