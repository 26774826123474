@import "../../../scss/variables";

.tile-card-container {
	width: 100%;
	display: flex;
	flex-flow: wrap;
	gap: 1rem;
	padding: 0 1rem;
}

.tile-card {
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	border: 1px solid rgba(0,0,0, 0.15);
	box-shadow: 0rem 0.25rem 0.25rem rgba(0,0,0, 0.25);
	transition: scale 0.2s;
	margin-bottom: 0.25rem !important;
	cursor: pointer;
}

.tile-card:hover { scale: 98%; }

.tile-list-item {
	padding-bottom: 0.50rem;
	padding-top: 0.50rem;
	display: flex;
	flex-flow: wrap;
	text-align: left;
	border-bottom: 1px solid rgba(0,0,0, 0.1);
}

.tile-list-item:last-child { border-bottom: none !important; }
.list-group-item { border: none !important; }

.tile-list-item .left {
	flex: 1;
	word-wrap: break-word;  
	margin-right: 1rem;
}

.tile-list-item .right {
	flex: 1;
	word-wrap: break-word;  
}

.tile-card-header {
	padding: 1rem 1.25rem;
	background: linear-gradient(to bottom, whitesmoke, #e6e6e6);
}

.pen-to-square {
	font-size: 18px;
	color: black;
}

.filter-count-container {
	margin-top: 0.75rem;
	float: left;
}

.pagination-container {
	margin-top: 0.75rem;
	float: right;
}

.bottom-left-container {
	float: left;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	margin-right: 10px;
}

.pageSize { max-width: 63px; }
.mat-mdc-header-row { background-color: #333 !important; }
.clickable-row:hover { cursor: pointer; }
.reduce-height { height: 20px; }
.red-text { color: #FF0000; }
.green-text { color: green }

// New Mobile Card Styling
.list-card {
	width: 100%;
	border-radius: 0.5rem;
	background-color: rgb(240, 240, 240);
	margin-bottom: 0.75rem;
	border: 1px solid rgba(0,0,0, 0.15);
	display: flex;
	cursor: pointer;
	transition: scale 0.2s;
}

.list-card:hover { scale: 98%; }

.list-card p {
	margin: 0;
	opacity: 0.75;
	font-size: .75rem;
}

.list-card h6 { margin: 0; }

.unique-field {
	flex: 1.5;
	min-width: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #cccccc;
	border-radius: 0.5rem 0 0 0.5rem;
}

.discrepancy-color { background-color: rgba(255, 99, 71, 0.3); }

.alert-field {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: baseline;
	padding: 0.75rem 1rem;
}

.alert-bubble {
	background-color: rgba(255, 99, 71, 0.3);
	margin: 0 !important;
	padding: 0.15rem 0.5rem;
	border-radius: 1rem;
	font-size: 0.75rem
}

.unique-item {
	flex: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0rem 1rem;
}

.unique-item-text {
	max-width: 200px;
	overflow: auto !important;
}
.unique-item-text::-webkit-scrollbar { display: none; }

.delete-field {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: last baseline;
	padding: 0.75rem 1rem;
	width: 100%;
}

.delete-btn-table { width: 100%; }

.delete-btn { width: 100%; }

.list-field {
	flex: 2;
	width: 100%;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.card-list-item {
	padding: 0.75rem;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	text-align: left;
	border-bottom: 1px solid rgba(0,0,0, 0.1);
}

.card-list-item h6 { 
	overflow: hidden;
	text-overflow: ellipsis; 
} 

.card-list-item:last-child { border-bottom: none !important; }
.display-none { display: none; }

.no-records-table {
	text-align: center;
	background-color: rgb(240, 240, 240);
	border-radius: 0.5rem;
	padding: 1rem;
	margin: 0.5rem;
}

.no-records {
	text-align: center;
	background-color: rgb(240, 240, 240);
	border-radius: 0.5rem;
	padding: 1rem;
	margin: 0.5rem;
}

::ng-deep .page-item { 
	width: 2rem; 
	min-width: fit-content;
	.page-link {
		text-wrap: nowrap;
		text-align: center;
		padding-left: 2px;
		padding-right: 2px;
	}
}

@media screen and (max-width: map-get($grid-breakpoints, "md")) {
	.bottom-left-container,
	.pagination-container, 
	.filter-count-container {
		float: none;
		display: block;
		margin-top: 2px;
		text-align: center !important;
	}

	.btn-container {
		display: block;
		margin: 0;
	}

	.pagination-container {
		float: none;
		display: block;
		margin: 0;
		text-align: center !important;
	}

	.d-flex { display: block !important; }
	.d-flex-table { display: block !important; }

	.pageSize {
		max-width: 63px;
		display: inline-block;
	}

	.unique-item-text { max-width: 100px; }
}

// Changes to make when the screen is printed
@media print {
	::-webkit-scrollbar { display: none; }

	.hide-on-print {
		display: none;
		visibility: hidden;
	}
}

// Mute the default toggle icon border for PrimeNG table toggle
::ng-deep .p-ripple.p-element.p-treetable-toggler.p-link { 
	border: none !important;
}

.pointer  { cursor: pointer !important; }

::ng-deep .tree-toggler,
.pointer.disabled  { cursor: text !important; }