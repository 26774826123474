import { afterNextRender, Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from "@angular/core";

@Component({
	selector: "pcg-shadow-container",
	templateUrl: "./shadow-container.component.html",
	styleUrls: ["./shadow-container.component.scss"],
	standalone: false
})
export class ShadowContainerComponent implements OnInit {
    /// The content to be rendered inside the shadow container.
    @Input() renderDataThreshold?: number = null;
    @Output() onDataRequest: EventEmitter<void> = new EventEmitter<void>();

    /// Signals to check if there is any overflow content above or below the provided content.
    hasOverflow: WritableSignal<boolean> = signal<boolean>(false);

    /// This is used to identify the instance of the component.
    instanceId: number = 0;

    /// Intersection observers for the start and end of the provided content.
    observer: IntersectionObserver = null;

    constructor() {
        afterNextRender(() => { this.checkForOverflow(); });
    }
    
    ngOnInit(): void {
        // Getting the count of pcg-shadow-container instances. Setting to 1 if it is not already set.
        window["pcg-shadow-container-count"] = window["pcg-shadow-container-count"] ?? 1;
    
        // Setting the instance id of the component.
        this.instanceId = window["pcg-shadow-container-count"]++;
    }
    
    /// Checking if there is any overflow content.
    checkForOverflow(): void {
        // Setting up IntersectionObserver for the content bottom.	
        this.observer = this.setIntersectionObserver('End', this.hasOverflow);
    }
    
    /// Setting up IntersectionObserver for the start and end of the element.
    setIntersectionObserver(elPosition: string, signal: WritableSignal<boolean>): IntersectionObserver {
        const el = document.getElementById(`content${elPosition}-${this.instanceId}`);
        const callBack = (entries) => { signal.set(!entries[0]?.isIntersecting); };
        const observer = new IntersectionObserver(callBack);
        observer.observe(el);
        return observer;
    }

    /// Handling the scroll event.
	onScroll(event: any) {
        if (this.renderDataThreshold) {
            const el = event.target;
            if ((el.scrollHeight - el.scrollTop - this.renderDataThreshold) <= (el.clientHeight)) {
                this.onDataRequest.emit();
            }
        }
	}
}
