<ng-select
	#theSelect
	class="pcg-select"
	[class.d-none]="showNativeSelect == null ? false : showNativeSelect"
	[multiple]="multiple"
	[placeholder]="placeholder ?? 'Select ...'"
	[labelForId]="labelForId"
	[(ngModel)]="value"
	[items]="items"
	(change)="onChange"
	(touch)="onTouched"
	groupBy="groupBy"
	bindLabel="text"
	bindValue="value"
	[clearable]="clearable"
	[virtualScroll]="virtualScroll"
	[searchable]="!isMobile"
	[disabled]="disabled"
    [closeOnSelect]="closeOnSelect"
>
	@if (multiple) {
		<ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
			<div class="ng-value" *ngFor="let item of items | slice:0:itemsShowLimit">
				<span class="ng-value-label">{{item.text}}</span>
				<span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
			</div>
			<div class="ng-value" *ngIf="items.length > itemsShowLimit">
				<span class="ng-value-label">{{items.length - itemsShowLimit}} more...</span>
			</div>
		</ng-template>
	}

	<ng-template *ngIf='notFoundTemplate' ng-notfound-tmp>
		<ng-container [ngTemplateOutlet]="notFoundTemplate" />
	</ng-template>

</ng-select>
<span class="disabled-only-text" [class.d-none]="showNativeSelect == null ? false : showNativeSelect">{{ text }}</span>

<ng-container *ngIf="showNativeSelect == null ? false : showNativeSelect">
	<select
		#theNativeSelect
		class="form-control"
		[(ngModel)]="value"
		[id]="labelForId"
		[name]="labelForId"
		(change)="onChange"
		(touch)="onTouched"
		[disabled]="disabled"
	>
		<option *ngIf="clearable" [ngValue]="value === undefined ? undefined : null">{{ placeholder }}</option>
		<option *ngFor="let item of items" [ngValue]="item.value">{{ item.text }}</option>
	</select>
</ng-container>
